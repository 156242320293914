<script setup>
import 'tippy.js/dist/tippy.css';
import { Tippy } from 'vue-tippy';
import { computed, ref, onMounted } from 'vue';

const props = defineProps({
    icon: { type: [Object, Function] },
    type: { type: [String], default: 'default' },
    size: { type: [String], default: 'default' },
    title: { type: [String], default: null },
    disabled: { type: Boolean, default: false },
});
const body = ref(null);
const baseClass = 'flex items-center space-x-1 border font-semibold capitalize leading-none';

const computedSizeClass = computed(
    () =>
        ({
            small: `h-8 p-2 rounded-lg text-sm`,
            default: `h-10 rounded-lg p-2 px-3 text-sm`,
        })[props.size],
);

const computedThemeClass = computed(
    () =>
        ({
            white: `bg-white enabled:supports-hover:hover:bg-gray-200`,
            default: `bg-white enabled:supports-hover:hover:bg-gray-200`,
            warning: `bg-amber-500 text-white border-amber-700 enabled:supports-hover:hover:bg-amber-200`,
            danger: `bg-rose-600 text-white border-rose-700 enabled:supports-hover:hover:bg-rose-500`,
        })[props.type],
);

onMounted(() => {
    body.value = document.body;
});
</script>

<template>
    <Tippy
        arrow-type="round"
        :arrow="!!title"
        :follow-cursor="false"
        :duration="0"
        :delay="250"
        :append-to="() => body"
        placement="bottom-end"
        trigger="mouseenter"
    >
        <template #content v-if="title">
            <div class="rounded bg-gray-200 px-2 py-1 text-xs text-black">{{ title }}</div>
        </template>
        <button
            class="disabled:cursor-not-allowed disabled:text-gray-600"
            :class="[baseClass, computedThemeClass, computedSizeClass]"
            v-bind="{ disabled }"
        >
            <component v-if="icon" :is="icon" class="h-4 w-4 stroke-[2]" />
            <span v-if="$slots.default">
                <slot />
            </span>
        </button>
    </Tippy>
</template>
